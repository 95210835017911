<template>
  <div class="invoice" :class="layoutType.value" v-if="!isLoading && (invoiceInfo.loaded || receiptInfo.loaded)">
    <div
      class="invoice__status-section"
      :class="preference === 'invoice' ? 'invoice-mode' : 'card-mode'"
      v-if="layoutType.isInvoice && !invoiceInfo.is_fulfilled
      && invoiceInfo.payment_medium"
    >
      <div class="invoice__status-section--col-left">
        <div class="payment-desc">
          <p class="payment-status" :class="dateDiff > 0 ? 'over-due': 'pending'">
            <span>
              <svg>
                <use v-bind="{ 'xlink:href': '#warning-icon' }" />
              </svg>
            </span>
            {{ paymentStatusInfo }}
          </p>
          <p
            class="pending-amount"
          >{{ formatCurrency(invoiceInfo.grand_total, invoiceInfo.currency, false, true) }}</p>
          <p class="due-date" v-if="invoiceInfo.payment_due_date">
            <label>Due date</label>
            {{$filters.formatDate(invoiceInfo.payment_due_date)}}
          </p>
        </div>
        <a v-if="invoiceInfo.payment_medium === 'invoice'"
        :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&invoice='+invoiceInfo.key"
         target="_blank" class="btn-style-common medium">Other payment method</a>
        <a v-else
        :href="link+'user/project/'+activeProject.key+'/wizard?step=payment&invoice='+invoiceInfo.key"
         target="_blank" class="btn-style-common medium">Pay Now</a>
      </div>
      <div class="invoice__status-section--col-right"
      v-if="invoiceInfo.invoice_payment_detail_raw
      && (!invoiceInfo.invoice_payment_detail_raw.is_paypal_invoice)">
        <p class="pay-to">Pay to</p>
        <p class="account-no" v-if="invoiceInfo.invoice_payment_detail_raw.bank_name">
          <label>Bank Name :</label> {{ invoiceInfo.invoice_payment_detail_raw.bank_name}}
        </p>
        <p class="account-no">
          <label>Account Number :</label> {{ invoiceInfo.invoice_payment_detail_raw.account_number}}
        </p>
        <p class="ifsc-code">
          <label>IFSC Code:</label> {{invoiceInfo.invoice_payment_detail_raw.ifsc_code}}
        </p>
        <p class="beneficiary">
          <label>Beneficiary Name:</label> {{ invoiceInfo.invoice_payment_detail_raw.beneficiary_name }}
        </p>
        <p class="virtual-id beneficiary"
        v-if="invoiceInfo && invoiceInfo.invoice_payment_detail_raw
        && invoiceInfo.invoice_payment_detail_raw.razorpay_virtual_id">
          <label>Razorpay Virtual ID :</label>
          {{invoiceInfo.invoice_payment_detail_raw.razorpay_virtual_id}}
        </p>
        <template v-if="invoiceInfo.invoice_payment_detail_raw.swift_code">
          <p class="beneficiary">
            <label>SWIFT Code:</label> {{ invoiceInfo.invoice_payment_detail_raw.swift_code}}
          </p>
        </template>
        <p class="payment-info">
          You have selected invoice as payment method.
          Make the payment through above account
        </p>
      </div>
      <div class="invoice__status-section--col-right"
      v-if="invoiceInfo.invoice_payment_detail_raw
      && invoiceInfo.invoice_payment_detail_raw.is_paypal_invoice">
        <p class="pay-to">Pay to</p>
        <p class="account-no">
          <label>Paypal Account :</label> {{ invoiceInfo.invoice_payment_detail_raw.paypal_account_id}}
        </p>
        <p class="ifsc-code">
          <label>Paynote:</label> {{invoiceInfo.paynote}}
        </p>
      </div>
    </div>
    <div
      class="invoice__status-section"
      :class="preference === 'invoice' ? 'invoice-mode' : 'card-mode'"
      v-else-if="layoutType.isInvoice && !invoiceInfo.is_fulfilled
      && invoiceInfo.billing && invoiceInfo.billing.last_used_payment_medium === 'card'
      "
    >
      <div class="invoice__status-section--col-left">
        <div class="payment-desc">
          <p class="payment-status" :class="dateDiff > 0 ? 'over-due': 'pending'">
            <span>
              <svg>
                <use v-bind="{ 'xlink:href': '#warning-icon' }" />
              </svg>
            </span>
            {{ paymentStatusInfo }}
          </p>
          <p
            class="pending-amount"
          >{{ formatCurrency(invoiceInfo.grand_total, invoiceInfo.currency) }}</p>
          <p class="due-date" v-if="invoiceInfo.payment_due_date">
            <label>Due date</label>
            {{$filters.formatDate(invoiceInfo.payment_due_date)}}
          </p>
        </div>
      </div>
      <!-- <div class="invoice__status-section--col-right other-payment">
        <a :href="link+'user/project/'+activeProject.key+'/edit?invoice='+invoiceInfo.key"
         target="_blank" class="btn primary">Proceed to Pay</a>
      </div> -->
    </div>
    <div class="invoice__main-section" v-if="invoiceInfo || receiptInfo">
      <div class="invoice__header">
        <div class="image-row">
          <div class="image-row__left-col">
            <img src="@/assets/img/rzlogo.svg" alt="Roanuz logo" />
            <img src="@/assets/img/RS.png" alt="Roanuz Sports logo" />
          </div>
          <div class="image-row__right-col" v-if="(invoiceInfo && invoiceInfo.status
          && invoiceInfo.status.isCancelledInvoice) ||
          (receiptInfo && receiptInfo.status && receiptInfo.status.isCancelled)">
            <svg class="image-row__right-col--icon cancelled-icon">
              <use v-bind="{ 'xlink:href': '#cancelled-icon' }" />
            </svg>
          </div>
          <div class="image-row__right-col" v-else-if="((invoiceInfo && invoiceInfo.is_fulfilled)
          || (receiptInfo.loaded && receiptInfo.payment_status.isPaymentSuccess))
          && ((invoiceInfo && invoiceInfo.status && !invoiceInfo.status.isCancelledInvoice)
          || (receiptInfo && receiptInfo.status && !receiptInfo.status.isCancelled))">
            <svg class="image-row__right-col--icon">
              <use v-bind="{ 'xlink:href': '#paid-icon' }" />
            </svg>
          </div>
        </div>
        <h1 class="page-header">
          <span v-if="layoutType.isTransaction">receipt</span>
          <span v-else><span v-if="invoiceInfo && invoiceInfo.is_proforma">ProForma</span> invoice</span>
        </h1>
        <div class="receipt-details" v-if="layoutType.isTransaction">
          <div class="receipt-details__left-col">
            <div class="row-top">
              <label>Paid on</label>
            </div>
            <div class="row-bottom">
              <p>{{$filters.formatDate(receiptInfo.created_at, 'Do MMM YYYY, h:mm A')}}</p>
            </div>
          </div>
          <div class="bill-details__mid-col">
            <label>Amount Received</label>
            <p class="amount">
              {{ formatCurrency(receiptInfo.amount, receiptInfo.currency) }}
              <span v-if="receiptInfo.kind !== 'money'">
                <svg>
                  <use v-bind="{ 'xlink:href': '#virtual-money-icon' }" />
                </svg>
              </span>
            </p>
          </div>
          <div class="bill-details__right-col">
            <label>Payment Method</label>
            <p v-if="receiptInfo.payment_medium">{{ receiptInfo.payment_medium }}</p>
            <p v-else>Upfront payment</p>
          </div>
        </div>
        <div class="bill-details" v-else>
          <div class="bill-details__left-col">
            <div class="row-top" v-if="invoiceInfo.purchase_order_number">
              <label>PO Number</label>
              <p>{{ invoiceInfo.purchase_order_number }}</p>
            </div>
            <div class="row-top" v-if="invoiceInfo.work_order_number">
              <label>WO Number</label>
              <p>{{ invoiceInfo.work_order_number }}</p>
            </div>
            <div class="row-top">
              <label>Bill number</label>
              <p>{{ invoiceInfo.bill_number }}</p>
            </div>
            <div class="row-bottom">
              <label>Bill date</label>
              <p>{{$filters.formatDate(invoiceInfo.generated_date)}}</p>
            </div>
          </div>
          <div class="bill-details__mid-col">
            <label>Grand Total</label>
            <p class="amount">{{ formatCurrency(invoiceInfo.grand_total, invoiceInfo.currency, false, true) }}</p>
          </div>
          <div class="bill-details__right-col" v-if="invoiceInfo.payment_due_date">
            <label>Due Date</label>
            <p>{{$filters.formatDate(invoiceInfo.payment_due_date)}}</p>
          </div>
        </div>
      </div>
      <div
        class="invoice__address font-bold"
        v-if="invoiceInfo && layoutType.isInvoice"
      >
        <div class="invoice__address-from">
          <h3>invoice from</h3>
          <address>
            {{ sellerInformation.CompanyName }}
            <br />
            <span class="font-normal">
              {{ sellerInformation.GateNo }},
              <br />{{ sellerInformation.Area }} - {{ sellerInformation.PinCode }}
            </span>
            <br />GSTIN: {{ sellerInformation.GSTIN }}
          </address>
        </div>
        <div class="invoice__address-to" v-if="invoiceInfo.project.billing">
          <h3>invoice to</h3>
          <address>
            {{ invoiceInfo.project.billing.contact_name }}
            <br />
            <span v-if="invoiceInfo.project.billing.company_name">
              {{ invoiceInfo.project.billing.company_name }}
              <br />
            </span>
            <span class="font-normal">
              {{ invoiceInfo.project.billing.address }}
              <br />
              {{ invoiceInfo.project.billing.city }}, {{ invoiceInfo.project.billing.state }}
              <br />
              {{ invoiceInfo.project.billing.country }}, {{ invoiceInfo.project.billing.zip_code }}
              <br />
              {{ invoiceInfo.project.billing.contact_email }}
            </span>
            <br />
            <span v-if="invoiceInfo.project.billing.gst_number">
              GSTIN: {{ invoiceInfo.project.billing.gst_number }}
            </span>
          </address>
        </div>
      </div>
      <div class="invoice__receipt-summary" v-if="layoutType.isTransaction">
        <div class="invoice__receipt-summary--col-left">
          <template v-if="activeProject.name">
            <label>Project</label>
            <p>{{ receiptInfo.project.name }}</p>
          </template>
          <template v-if="receiptInfo.from_account">
            <label>From Account</label>
            <p>{{ receiptInfo.from_account }}</p>
          </template>
          <template v-if="receiptInfo.to_account">
            <label>To Account</label>
            <p>{{ receiptInfo.to_account }}</p>
          </template>
        </div>
        <div class="invoice__receipt-summary--col-right">
          <label v-if="receiptInfo.payment_gateway">Payment Gateway</label>
          <p v-if="receiptInfo.payment_gateway">
            {{ receiptInfo.payment_gateway }}
          </p>
          <label>Transaction Number</label>
          <p>{{ receiptInfo.key }}</p>
        </div>
      </div>
      <div
        class="invoice__project-info"
        v-if="invoiceInfo && layoutType.isInvoice"
      >
        <h3>Project</h3>
        <p>{{ invoiceInfo.project.name }}</p>
      </div>

      <div
        class="invoice__pricing-table"
        v-if="invoiceInfo && layoutType.isInvoice"
      >
        <div class="api-usage invoice">
          <div class="api-usage__table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Products</th>
                  <th>
                    Price
                    <svg>
                      <use v-bind="{'xlink:href':'#help-icon'}" />
                    </svg>
                  </th>
                  <th>Quantity</th>
                  <th>Line Total</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(data, index) in invoiceInfo.lines" :key="index">
                  <tr class="usage-details"
                    :class="data.line_total < 0 ? 'add-color': ''">
                    <td class="usage-details__api-name">
                      <span>{{data.name}}</span>
                    </td>
                    <td class="usage-details__unit-price">
                      <span>{{formatCurrency(data.unit_amount, data.currency, false, true)}}</span>
                    </td>
                    <td class="usage-details__quantity-info">
                      <span>{{data.quantity}}</span>
                    </td>
                    <td>
                      <span
                        class="usage-details__price-info"
                      >{{formatCurrency(data.line_total, data.currency, false, true)}}</span>
                    </td>
                  </tr>
                </template>
                <tr class="usage-details__discount-entries"
                    v-for="(discount,index) in invoiceInfo.discount_lines" :key="index">
                  <td></td>
                  <td></td>
                  <td>{{ discount.name }}</td>
                  <td class="discount-amount">
                    {{ formatCurrency(discount.line_total, invoiceInfo.currency, false, true) }}
                  </td>
                </tr>
                <tr class="usage-details__discount-entries"
                    v-for="(discount,index) in invoiceInfo.coupon_discount_lines" :key="index">
                  <td></td>
                  <td></td>
                  <td>{{ discount.name }}</td>
                  <td class="discount-amount">{{ formatCurrency(discount.line_total, invoiceInfo.currency) }}</td>
                </tr>
                <tr class="usage-details__subtotal-entries">
                  <td></td>
                  <td></td>
                  <td>Sub Total</td>
                  <td>{{ formatCurrency(invoiceInfo.grand_total_before_tax, invoiceInfo.currency, false, true) }}</td>
                </tr>
                <tr
                  class="usage-details__tax-entries"
                  v-for="(taxItem, index) in invoiceInfo.tax_lines"
                  :key="index"
                >
                  <td></td>
                  <td></td>
                  <td>{{ taxItem.name }} ({{ taxItem.tax_percentage }}%)</td>
                  <td>{{ formatCurrency(taxItem.amount, invoiceInfo.currency, false, true) }}</td>
                </tr>
                <tr class="usage-details__total-amount">
                  <td></td>
                  <td></td>
                  <td>Grand Total</td>
                  <td>{{formatCurrency(invoiceInfo.grand_total, invoiceInfo.currency, false, true)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="transaction-result" v-if="invoiceInfo.is_fulfilled">
          <svg>
            <use v-bind="{ 'xlink:href': '#tickmark-icon' }" />
          </svg>
          <p>Payment Successful</p>
        </div>
        <!-- <div class="payment-method" v-if="invoiceInfo.is_fulfilled">
          <svg>
            <use v-bind="{ 'xlink:href': '#card-icon' }" />
          </svg>
          <p>Paid by {{ invoiceInfo.transactions[0].payment_medium }}</p>
        </div> -->
      </div>
      <div class="invoice__seller-information razor-pay-info" v-if="layoutType.isInvoice
       && invoiceInfo.invoice_payment_detail_raw
       && (!invoiceInfo.invoice_payment_detail_raw.is_paypal_invoice)">
        <h3>Pay To</h3>
        <div class="flex-box">
          <div class="flex-box__col-left">
            <label>Beneficiary Name:</label>
            <p>{{ invoiceInfo.invoice_payment_detail_raw.beneficiary_name }}</p>
            <br />
            <label>Account Number:</label>
            <p>{{ invoiceInfo.invoice_payment_detail_raw.account_number}}</p>
            <br />
            <template v-if="invoiceInfo.invoice_payment_detail_raw.bank_name">
              <label>Bank Name:</label>
              <p>{{invoiceInfo.invoice_payment_detail_raw.bank_name}}</p>
              <br />
            </template>
          </div>
          <div class="flex-box__col-right">
            <label>IFSC:</label>
            <p> {{ invoiceInfo.invoice_payment_detail_raw.ifsc_code}}</p>
            <br />
            <label v-if="invoiceInfo && invoiceInfo.invoice_payment_detail_raw
              && invoiceInfo.invoice_payment_detail_raw.razorpay_virtual_id">
              Razorpay Virtual ID: </label>
            <p v-if="invoiceInfo && invoiceInfo.invoice_payment_detail_raw
              && invoiceInfo.invoice_payment_detail_raw.razorpay_virtual_id">
              {{invoiceInfo.invoice_payment_detail_raw.razorpay_virtual_id}}</p>
            <label v-if="invoiceInfo && invoiceInfo.invoice_payment_detail_raw
              && invoiceInfo.invoice_payment_detail_raw.swift_code">
              SWIFT CODE: </label>
            <p v-if="invoiceInfo && invoiceInfo.invoice_payment_detail_raw
              && invoiceInfo.invoice_payment_detail_raw.swift_code">
              {{invoiceInfo.invoice_payment_detail_raw.swift_code}}</p>
          </div>
        </div>
      </div>
      <div class="invoice__seller-information razor-pay-info" v-if="layoutType.isInvoice
       && invoiceInfo.invoice_payment_detail_raw
       && invoiceInfo.invoice_payment_detail_raw.is_paypal_invoice">
        <h3>Pay To</h3>
        <div class="flex-box paypal">
          <div class="flex-box__col-left">
            <label>Paypal Account:</label>
            <p>{{ invoiceInfo.invoice_payment_detail_raw.paypal_account_id }}</p>
            <br />
          </div>
          <div class="flex-box__col-right">
            <label>Paynote:</label>
            <p> {{ invoiceInfo.paynote}}</p>
            <br />
          </div>
        </div>
      </div>
      <div class="invoice__seller-information" v-if="layoutType.isInvoice">
        <h3>Seller Information</h3>
        <div class="flex-box">
          <div class="flex-box__col-left">
            <label>Company Name:</label>
            <p>{{ sellerInformation.CompanyName }}</p>
            <br />
            <label>CIN:</label>
            <p>{{ sellerInformation.CIN }}</p>
            <br />
            <label>PAN:</label>
            <p>{{ sellerInformation.PAN }}</p>
          </div>
          <div class="flex-box__col-right">
            <label>GSTIN:</label>
            <p>{{ sellerInformation.GSTIN }}</p>
            <br />
            <label>SAC Code:</label>
            <p>{{ invoiceInfo.sac_code || sellerInformation.SacCode }}</p>
          </div>
        </div>
      </div>
      <div class="invoice__notes" v-if="layoutType.isTransaction">
        <h3>Notes</h3>
        <p>{{receiptInfo.notes}}</p>
      </div>
      <div class="invoice__contact-info">
        <h3>Contact Information</h3>
        <div class="invoice__contact-info--link">
          <a :href="supportNumbers.supportMail.link" title="Mail support">
            <svg class="mail-icon">
              <use v-bind="{ 'xlink:href': '#mail-icon' }" />
            </svg>
            <p>{{supportNumbers.supportMail.label}}</p>
          </a>
          <a :href="supportNumbers.usTollfree.link" title="Phone support">
            <svg>
              <use v-bind="{ 'xlink:href': '#phone-icon' }" />
            </svg>
            <p>{{supportNumbers.usTollfree.label}}</p>
          </a>
        </div>
        <div class="invoice__contact-info--thank-mesg">
          <svg>
            <use v-bind="{ 'xlink:href': '#heart-icon'}" />
          </svg>
          <p>Thanks for choosing Roanuz Sports</p>
        </div>
      </div>
    </div>
    <TransactionList
      v-if="invoiceInfo && invoiceInfo.transactions && invoiceInfo.transactions.length"
      :transactionInfo="invoiceInfo.transactions"
    />
    <InvoiceList :invoiceListInfo="receiptInfo.invoices" v-if="layoutType.isTransaction"/>
    <div class="invoice__status-section--options">
      <button @click="sendEmail()">
        <svg class="mail-icon" :class="emailSentStatus ? 'sent' : ''">
          <use v-if="emailSentStatus" v-bind="{ 'xlink:href': '#mail-sent-icon' }" />
          <use v-else v-bind="{ 'xlink:href': '#mail-icon' }" />
        </svg>
        <p :class="emailSentStatus ? 'mail-sent' : null">
          <template v-if="emailSentStatus">
            Email sent successfully
          </template>
          <template v-else>
            Email me the
            <span v-if="layoutType.isTransaction">receipt</span>
            <span v-else>invoice</span>
          </template>
        </p>
      </button>
      <button @click="downloadbill()">
        <svg class="download-icon">
          <use v-bind="{ 'xlink:href': '#download-icon' }" />
        </svg>
        <p>Download
          <span v-if="layoutType.isTransaction">receipt</span>
          <span v-else>invoice</span>
        </p>
      </button>
      <button @click="printWindow()">
        <svg class="print-icon">
          <use v-bind="{ 'xlink:href': '#print-icon' }" />
        </svg>
        <p>Print
          <span v-if="layoutType.isTransaction">receipt</span>
          <span v-else>invoice</span>
        </p>
      </button>
      <div class="admin-actions" v-if="activeUser.groups && activeUser.groups.length > 0 ">
        <h3 v-if="!layoutType.isTransaction">Admin Actions</h3>
        <button v-if="!layoutType.isTransaction"
         @click="verifyInvoice()">
          <svg class="invoice-icon">
            <use v-bind="{ 'xlink:href': '#invoice-icon' }" />
          </svg>
          <p> Approve
            <span>invoice</span>
          </p>
        </button>
        <button v-if="!layoutType.isTransaction"
         @click="cancelInvoice()">
          <svg class="invoice-icon">
            <use v-bind="{ 'xlink:href': '#help-icon' }" />
          </svg>
          <p> Cancel
            <span>invoice</span>
          </p>
        </button>
        <!-- <button v-if="layoutType.isTransaction"
          @click="cancelTransaction()">
          <svg class="invoice-icon">
            <use v-bind="{ 'xlink:href': '#transaction-icon' }" />
          </svg>
          <p> Cancel
            <span >receipt</span>
          </p>
        </button> -->
      </div>
    </div>
  </div>
  <div class="loader" v-else>
    <LoadingPlaceholder />
  </div>
</template>

<style lang="scss">
.console-home {
  @media print {
    &__super-menu,
    &__senior-menu,
    &__header,
    &__footer {
      display: none !important;
    }
    &__main-section {
      margin: auto !important;
    }
    .dynamic-content {
      margin: auto !important;
      position: relative !important;
      padding: size(0);
      &--wrapper {
        margin: auto !important;
        padding: size(0) !important;
      }

      .invoice__status-section,
      .invoice__status-section--options,
      .transaction {
        display: none;
      }

      .invoice__main-section {
        min-width: 100%;
        padding: size(0);
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        .invoice__pricing-table .api-usage.invoice{
         min-width: 100%;
        }
      }

      &.invoice.transaction {
        .invoice__main-section {
          min-width: 100%;
        }
      }

      .invoice-history {
        display: none;
      }
    }
  }
}
.loader {
  .loading-placeholder {
    max-width: size(841);
    padding: unset;
    position: unset;
    top: unset;
    min-height: size(500);
    .loading-placeholder__header.skeleton-box, .side-nav {
      display: none;
    }
    .loading-placeholder__main .left-col {
      height: unset;
      margin-right: unset;
    }
  }
}
</style>

<style lang="scss" scoped>
.invoice {
  --invoice--BorderColor: #dadada;

  color: var(--global--main--title--Color);
  position: relative;

  &__main-section,
  &__status-section {
    border-radius: size(10);
    padding: size(40) size(30) size(50);
    background: var(--global--white--Color);
    max-width: size(841);
    min-width: size(841);
  }

  &__status-section {
    display: flex;
    justify-content: space-between;
    margin: size(40) size(0) size(20) size(0);
    padding-left: size(50);
    padding-right: size(50);
    background: var(--global--white--Color);
    &.card-mode {
      flex-direction: column;
      .invoice__status-section {
        &--col-left {
          display: flex;
          justify-content: space-between;
          .due-date {
            margin-bottom: size(0);
          }
          button {
            align-self: flex-start;
          }
        }
        &--col-right {
          display: none;
        }
      }
    }
    &--col-left {
      .due-date {
        @include font-styles($type: 'label', $size: 'md', $weight: 'semibold');
        margin-bottom: size(30);
        label {
          font-weight: var(--global--primary--regular--FontWeight);
        }
      }
      .pending-amount {
        margin-bottom: size(11);
        font-size: size(40);
        line-height: size(49);
        font-weight: 800;
      }
      .payment-status {
        margin-bottom: size(15);
        padding: size(6) size(10);
        font-size: size(12);
        line-height: size(15);
        border-radius: size(20);
        background: rgba(224, 7, 81, 0.06);
        display: inline-block;
        color: var(--global--error--BackgroundColor);
        span {
          margin-right: size(5);
        }
        svg {
          height: size(9);
          width: size(9);
          fill: var(--global--error--BackgroundColor);
        }
      }
    }
    &--col-right {
      align-self: flex-start;
      @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      .pay-to {
        @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
        margin-bottom: size(12);
      }
      .payment-info {
        max-width: size(336);
        color: var(--global--supportive--Color);
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      }
      .beneficiary {
        margin-bottom: size(10);
      }
      .ifsc-code,
      .account-no {
        margin-bottom: size(16);
      }
    }
    &--options {
      position: absolute;
      top: size(0);
      right: size(-130);
      width: size(200);
      svg {
        fill: var(--global--common-link--Color);
        height: size(11);
        &.mail-icon {
          width: size(15);
          &.sent {
            fill: var(--global--main--title--Color);
          }
        }
        &.download-icon {
          width: size(11);
        }
        &.print-icon, &.invoice-icon {
          width: size(13);
        }
      }
      p {
        display: inline-block;
        margin-left: size(16);
        color: var(--global--common-link--Color);
      }
      button {
        display: block;
        margin-bottom: size(20);
        cursor: pointer;
        outline: none;
        p.mail-sent {
          color: var(--global--supportive--title--Color);
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: size(0) size(20) size(20);
    border-bottom: size(1) solid var(--invoice--BorderColor);
    .image-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: size(20);
      min-height: size(75);
      &__left-col {
        img:not(:first-child) {
          padding-left: size(30);
        }
      }
      &__right-col {
        &--icon {
          height: size(72);
          width: size(72);
          &.cancelled-icon {
            width: size(111);
          }
        }
      }
    }
    .page-header {
      font-size: var(--global--display--md--FontSize);
      line-height: var(--global--display--xxs--LineHeight);
      letter-spacing: size(5);
      margin-bottom: size(30);
      text-transform: uppercase;
    }
    .bill-details,
    .receipt-details {
      display: flex;
      justify-content: flex-start;

      label {
        @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
        display: inline-block;
      }
      p {
        @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      }
      &__left-col {
        min-width: 55%;
        p {
          margin-left: size(10);
          display: inline-block;
        }
        .row-top {
          margin-bottom: size(10);
        }
      }

      &__mid-col {
        min-width: 25%;
        .amount {
          font-size: size(28);
          line-height: size(34);
          font-weight: 800;
          svg {
            width: size(25);
            height: size(25);
            display: inline-block;
            vertical-align: top;
            margin-top: size(4);
            fill: var(--global--virtual-icon--BackgroundColor);
          }
        }
      }
      &__right-col {
        min-width: 20%;
        margin-left: size(50);
        label {
          margin-bottom: size(10);
        }
        p {
          @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
          text-transform: capitalize;
        }
      }
    }
    .receipt-details {
      &__left-col {
        p {
          margin-left: size(0);
        }
      }
    }
  }

  &__address {
    display: flex;
    // justify-content: space-between;
    padding: size(30) size(20);
    font-size: var(--global--text--md--FontSize);
    line-height: var(--global--display--xxs--LineHeight);
    h3 {
      text-transform: uppercase;
      margin-bottom: size(10);
    }
    &-from {
      min-width: 55%;
    }
  }

  &__receipt-summary {
    display: flex;
    padding-left: size(20);
    padding-right: size(20);
    padding-bottom: size(30);
    border-bottom: size(1) solid var(--invoice--BorderColor);
    &--col-left {
      min-width: 55%;
      label {
        @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
        display: inline-block;
        padding-top: size(17);
        padding-bottom: size(8);
      }
      p {
        @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      }
    }
    &--col-right {
      label {
        @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
        display: inline-block;
        padding-top: size(17);
        padding-bottom: size(8);
      }
      p {
        @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      }
    }
  }

  &__pricing-table {
    .transaction-result {
      margin-top: size(20);
      margin-right: size(20);
    }
    .payment-method {
      margin-top: size(10);
      margin-right: size(20);
    }
    p {
      margin-left: size(10);
      display: inline-block;
      @include font-styles($type: 'display', $size: 'xxs', $weight: 'regular');
    }
    svg {
      height: size(13);
      width: size(18);
      fill: var(--global--success--BackgroundColor);
    }
    text-align: right;
  }

  &__seller-information {
    &.razor-pay-info {
      .flex-box {
        &__col-left {
          p {
            padding-left: size(8);
          }
        }
        &__col-right {
          p {
            padding-left: size(8);
          }
        }
      }
    }
    margin-top: size(30);
    margin-bottom: size(20);
    padding: size(0) size(20);
    border-bottom: size(1) solid var(--invoice--BorderColor);
    h3 {
      margin-bottom: size(20);
      @include font-styles($type: 'display', $size: 'xxs', $weight: 'semibold');
    }
    p {
      display: inline-block;
      @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      margin-bottom: size(20);
      color: var(--global--main--Color);
    }

    label {
      display: inline-block;
      @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
    }

    .flex-box {
      display: flex;
      justify-content: space-between;
      &.paypal {
        justify-content: start;
        .flex-box__col-right {
          margin-left: size(150);
        }
      }
      &__col-left {
        label {
          min-width: size(130);
        }
      }
      &__col-right {
        label {
          min-width: size(105);
        }
      }
    }
  }

  &__notes {
    padding: size(20);
    border-bottom: size(1) solid var(--invoice--BorderColor);
    h3 {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
      padding-bottom: size(14);
    }
    p {
      @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
    }
  }

  &__project-info {
    padding: size(30) size(20) size(10);
    h3 {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
      margin-bottom: size(10);
    }
    p {
      @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
    }
  }

  &__contact-info {
    padding: size(30) size(20) size(0) size(20);
    &--link {
      padding-bottom: size(55);
    }
    h3 {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
      margin-bottom: size(15);
    }
    a {
      @include font-styles($type: 'text', $size: 'md', $weight: 'regular');
      &:not(:first-of-type) {
        padding-left: size(40);
      }
      svg {
        height: size(12);
        width: size(12);
        &.mail-icon {
          height: size(10);
          width: size(13);
        }
      }
    }
    p {
      display: inline-block;
      padding-left: size(10);
      color: var(--global--main--Color);
    }

    &--thank-mesg {
      p {
        @include font-styles(
          $type: 'display',
          $size: 'xxs',
          $weight: 'regular'
        );
        padding-left: size(20);
        display: inline;
        vertical-align: middle;
      }
      svg {
        vertical-align: middle;
        height: size(28);
        width: size(32);
      }
    }
  }

  .font-bold {
    font-weight: var(--global--primary--semibold--FontWeight);
  }
  .font-normal {
    font-weight: var(--global--primary--regular--FontWeight);
  }
  &.receipt {
    .invoice {
      &__status-section,
      &__address,
      &__project-info,
      &__pricing-table,
      &__seller-information {
        display: none;
      }
    }
  }
  .admin-actions {
    padding-top: size(20);
    h3 {
      padding-bottom: size(20);
      font-weight: 600;
    }
  }
  .api-usage {
    max-width: size(320);
    margin: 0 auto;
    background: var(--global--white-Color);
    border: size(1) solid var(--global--line--BackgroundColor);
    border-radius: size(10);
    padding: size(30) size(0) size(60);
    overflow: hidden;
    @media screen and (min-width: $breakpoint-md) {
      max-width: size(680);
      padding: size(30) size(32) size(60);
    }
    @media screen and (min-width: $breakpoint-lg) {
      min-width: size(781);
      max-width: size(1130);
    }
    .api-usage__table-wrapper {
      overflow-x: auto;
    }
    table {
      width: 100%;
      thead {
        tr {
          th {
            color: var(--global--main--title--Color);
            @include font-styles(
              $type: 'label',
              $size: 'md',
              $weight: 'normal'
            );
            text-align: right;
            padding-bottom: size(12);
            border-bottom: size(1) solid
              var(--global--line--table--BackgroundColor);
            @media screen and (min-width: $breakpoint-md) {
              @include font-styles(
                $type: 'text',
                $size: 'md',
                $weight: 'normal'
              );
            }
            &:first-child {
              width: size(170);
              text-align: left;
              padding-left: size(10);
              @media screen and (min-width: $breakpoint-md) {
                width: size(320);
              }
              @media screen and (min-width: $breakpoint-lg) {
                padding-left: size(50);
                width: size(650);
              }
            }
            &:last-child {
              width: size(80);
              @media screen and (min-width: $breakpoint-md) {
                width: size(130);
              }
            }
            svg {
              display: inline-block;
              margin-left: size(10);
              width: size(11);
              height: size(11);
            }
          }
        }
      }
      tr {
        td {
          padding: size(10) 0 size(5);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
          @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
          @media screen and (min-width: $breakpoint-md) {
            @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
          }
          text-align: right;
          color: var(--global--supportive--title--Color);
          &:first-child {
            text-align: left;
            padding-left: size(10);
            @media screen and (min-width: $breakpoint-lg) {
              padding-left: size(50);
            }
          }
          &.usage-details__quantity-info {
            width: size(100);
          }
          &.usage-details__unit-price {
            width: size(100);
          }
          .usage-details__price-info {
            display: inline-block;
            width: size(135);
            @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
            @media screen and (min-width: $breakpoint-md) {
              @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
            }
          }
          &:hover {
            .link {
              svg {
                display: inline-block;
              }
            }
          }
          .link {
            display: inline-block;
            svg {
              display: none;
              width: size(10);
              height: size(10);
              margin-left: size(15);
            }
          }
          & > img {
            display: inline-block;
            width: size(16);
            height: size(10);
            margin-left: size(10);
            cursor: pointer;
            vertical-align: top;
            margin-top: size(8);
            transition: transform 0.3s linear;
            @media screen and (min-width: $breakpoint-md) {
              margin-left: 0;
            }
            @media screen and (min-width: $breakpoint-lg) {
              margin-left: size(10);
            }
            &.inv-icon {
              margin-top: size(4);
              transform: rotateX(180deg);
            }
          }
        }
        &.usage-details__extra-entries {
          td {
            height: size(40);
            &:last-child {
              text-align: right;
              padding-right: size(40);
            }
          }
        }
        &.usage-details__total-amount {
          td {
            padding-top: size(20);
            text-align: right;
            @include font-styles(
              $type: 'text',
              $size: 'lg',
              $weight: 'bold'
            );
            &:nth-child(3) {
              width: size(110);
            }
          }
        }
        &.usage-details__graph-info {
          border-bottom: 0;
          td {
            padding: 0;
            & > div {
              border: size(1) solid var(--global--line--BackgroundColor);
              border-top: none;
              padding: size(20) 0;
              h3 {
                @include font-styles(
                  $type: 'text',
                  $size: 'md',
                  $weight: 'normal'
                );
                padding-bottom: size(10);
                padding-left: size(48);
              }
              span {
                display: inline-block;
                padding-left: size(48);
                @include font-styles(
                  $type: 'display',
                  $size: 'md',
                  $weight: 'bold'
                );
              }
            }
          }
        }
      }
    }
    &.invoice {
      padding: size(20) size(15);
      min-width: size(781);
      table {
        thead {
          tr {
            th {
              @include font-styles(
                $type: 'text',
                $size: 'md',
                $weight: 'semibold'
              );
              text-transform: uppercase;
              border-bottom: size(1) solid
                var(--global--supportive--title--Color);
              padding: size(0) size(10) size(10) size(10);
            }
            svg {
              display: none;
            }
          }
        }
        tbody {
          tr {
            &:first-child {
              td {
                padding-top: size(20);
              }
            }
            td {
              padding: size(8) size(10) size(8) size(10);
            }
            &.usage-details {
              &__total-amount {
                td {
                  &:nth-child(3) {
                    min-width: size(150);
                  }
                }
              }
              &.add-color {
                td {
                  color: #00A656;
                }
              }
              &__extra-entries {
                display: none;
              }
              &__subtotal-entries, &__discount-entries {
                td {
                  border-bottom: none;
                  padding-top: size(10);
                  text-transform: capitalize;
                  @include font-styles(
                      $type: 'label',
                      $size: 'lg',
                      $weight: 'semibold'
                    );
                  &:last-child {
                    font-weight: var(--global--primary--bold--FontWeight);
                  }
                  &.discount-amount {
                    color: #00A656;
                  }
                }
              }
              &__tax-entries {
                td {
                  padding-top: size(5);
                  padding-bottom: size(5);
                  border-bottom: none;
                  @include font-styles(
                      $type: 'text',
                      $size: 'md',
                      $weight: 'semibold'
                    );
                  &:last-child {
                    font-weight: var(--global--primary--bold--FontWeight);
                  }
                }
              }
              &__total-amount {
                td {
                  padding-top: size(10);
                  border: none;
                }
              }
            }
            &:nth-last-child(2) {
              border-bottom: size(1) solid var(--global--main--title--Color);
              td {
                padding-bottom: size(16);
              }
            }
          }
        }
      }
      img.expand-icon {
        display: none;
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Config from '@/config';
import { Enum } from '@/core/utils/enum';
import TransactionList from '@/components/TransactionList.vue';
import { currencyMethodsMixin } from '@/mixin/currency';
import { Invoice, Transaction, sellerInformation } from '@/store/modules/order';
import { InvoiceReport } from '@/store/modules/admin';
import InvoiceList from '@/components/InvoiceList.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import { supportNumbers } from '@/store/modules/project';

const LayoutTypeList = new Enum({
  Transaction: 'transaction',
  Invoice: 'invoice',
});

export default {
  name: 'invoice',
  mixins: [currencyMethodsMixin],
  components: { TransactionList, InvoiceList, LoadingPlaceholder },
  props: {
    preference: {
      type: String,
      default: 'invoice', // invoice, card
    },
  },
  data() {
    return {
      invoiceInfo: new Invoice(),
      receiptInfo: new Transaction(),
      usage: null,
      isOverDue: false,
      paymentStatusInfo: 'Waiting for payment',
      LayoutTypeList,
      sellerInformation,
      emailSentStatus: false,
      isLoading: true,
      link: Config.sportsHost,
      supportNumbers,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeUser: (state) => state.user.activeUser,
    }),
    layoutType() {
      if (this.$route.name === LayoutTypeList.Invoice.value) {
        return LayoutTypeList.Invoice;
      }
      return LayoutTypeList.Transaction;
    },
  },
  created() {
    if (this.activeProject.loaded) {
      this.fetchInvoiceDetails(this.layoutType.value);
    }
  },
  methods: {
    fetchInvoiceDetails(layoutType) {
      if (layoutType === 'invoice') {
        Invoice.fetchInvoice(this.$route.params.invoicekey)
          .then((resp) => {
            this.isLoading = false;
            this.invoiceInfo = resp;
            if (this.invoiceInfo.payment_due_date) {
              this.findOverDueStatus(this.invoiceInfo.payment_due_date);
            }
          })
          .catch((err) => {
            console.log('selected invoice error', err);
          });
      } else {
        Transaction.fetchTransaction(this.$route.params.transactionkey)
          .then((resp) => {
            this.isLoading = false;
            this.receiptInfo = resp;
          })
          .catch((err) => {
            console.log('selected transaction error', err);
          });
      }
    },
    findOverDueStatus(dueDate) {
      /* eslint-disable comma-dangle */
      const currentDate = moment();
      const dateDiff = Math.floor(
        moment.duration(currentDate.diff(moment.unix(dueDate))).asDays()
      );
      if (dateDiff > 9) {
        this.isOverDue = true;
        this.paymentStatusInfo = 'Overdue';
      }
    },
    printWindow() {
      window.print();
    },
    downloadbill() {
      let url = null;
      if (this.layoutType.isInvoice) {
        url = this.invoiceInfo.downloadLink;
      } else {
        url = this.receiptInfo.downloadLink;
      }
      window.open(url, '_blank');
    },
    sendEmail() {
      if (this.layoutType.isInvoice) {
        Invoice.sendInvoiceEmail(this.$route.params.invoicekey).then((resp) => {
          if (resp) {
            this.emailSentStatus = true;
          }
        }).catch((err) => err);
      } else {
        Transaction.sendTransactionEmail(this.$route.params.transactionkey).then((resp) => {
          if (resp) {
            this.emailSentStatus = true;
          }
        });
      }
    },
    cancelInvoice() {
      return InvoiceReport.action(this.$route.params.invoicekey, { action: 'cancel_invoice' });
    },
    verifyInvoice() {
      return InvoiceReport.action(this.$route.params.invoicekey, { action: 'approve_invoice' });
    },
  },
};
</script>
