<template>
  <div class="invoice-history">
    <h2>Invoice</h2>
    <div class="invoice-history__table-wrapper">
      <table class="invoice">
        <thead>
          <tr>
            <th>DATE</th>
            <th>BILL NO</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody v-if="invoiceListInfo">
          <tr class="invoice" v-for="(invoice, index) in invoiceListInfo" :key="index"
           @click="redirectToInvoice(invoice.key)">
            <td class="invoice__date">{{$filters.formatDate(invoice.generated_date)}}</td>
            <td class="invoice__invoice-number">{{invoice.key}}</td>
            <td class="invoice__amount">{{ formatCurrency(invoice.grand_total, invoice.currency) }}</td>
            <td>
              <span
                :class="['payment-status',
                (invoice.is_fulfilled  && !invoice.status.isCancelledInvoice)
                ? 'payment_transaction_success' : 'payment_transaction_failed']"
              >
                <svg>
                  <use v-if="invoice.is_fulfilled && !invoice.status.isCancelledInvoice"
                  v-bind="{ 'xlink:href': '#tickmark-icon'}" />
                  <use v-else v-bind="{ 'xlink:href': '#clock-icon'}" />
                </svg>
                <span v-if="invoice.is_fulfilled  && !invoice.status.isCancelledInvoice"
                 class="mesg">Paid</span>
                <span v-else-if="invoice.status.isCancelledInvoice">Invoice Cancelled</span>
                <span v-else>Unpaid</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  mixins: [currencyMethodsMixin],
  name: 'InvoiceList',
  props: {
    invoiceListInfo: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
  methods: {
    redirectToInvoice(invoicekey) {
      this.$router.push({ name: 'invoice', params: { invoicekey } });
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-history {
  font-family: var(--global--primary--FontFamily);
  max-width: size(320);
  margin: 0;
  overflow: hidden;
  padding-top: size(30);
  @media screen and (min-width: $breakpoint-md) {
    max-width: size(700);
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(841);
  }
  h2 {
    @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
    padding-bottom: size(20);
  }
  .invoice-history__table-wrapper {
    overflow-x: auto;
  }
  table {
    background: var(--global--white--Color);
    border-radius: size(10);
    border: size(1) solid var(--global--line--BackgroundColor);
    border-collapse: separate;
    padding: size(0) size(15) size(30);
    width: 100%;
    text-align: left;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(0) size(32) size(30);
    }
    thead {
      tr {
        th {
          @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
          color: var(--global--link--dark--Color);
          padding: size(42) 0 size(18);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
          padding-left: size(20);
          @media screen and (min-width: $breakpoint-md) {
            padding-left: 0;
          }
          &:first-child {
            @media screen and (min-width: $breakpoint-md) {
              padding-left: size(10);
            }
            @media screen and (min-width: $breakpoint-lg) {
              padding-left: size(50);
            }
          }
          &:last-child {
            width: size(100);
            @media screen and (min-width: $breakpoint-lg) {
              width: size(180);
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            color: var(--global--common-link--Color);
            cursor: pointer;
          }
          .invoice__icon-wrapper {
            display: inline-block;
          }
        }
        td {
          @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
          color: var(--global--heading--Color);
          padding: size(30) 0 size(16);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
          padding-left: size(20);
          @media screen and (min-width: $breakpoint-md) {
            padding-left: 0;
          }
        }
        .invoice__invoice-number {
          color: var(--global--main--Color);
        }
        .invoice__date {
          color: var(--global--heading--Color);
          @media screen and (min-width: $breakpoint-md) {
            padding-left: size(10);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(50);
          }
        }
        .invoice__amount {
          color: var(--global--heading--Color);
          font-weight: var(--global--primary--semibold--FontWeight);
        }
        .payment-status {
          &:hover {
            color: var(--global--common-link--Color);
          }
        }
        .invoice__icon-wrapper {
          display: none;
          a {
            display: inline-block;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
          }
          svg {
            cursor: pointer;
            margin-right: size(20);
            @media screen and (min-width: $breakpoint-lg) {
              margin-right: size(40);
            }
            &:last-child {
              margin-right: 0;
            }
            &.view-icon {
              width: size(17);
              height: size(12);
            }
            &.mail-icon {
              width: size(16);
              height: size(12);
            }
            &.download-icon {
              width: size(12);
              height: size(12);
            }
          }
        }
      }
    }
  }
  .payment-status {
    @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
    color: var(--global--main--Color);
    &:hover {
      svg {
        fill: var(--global--common-link--Color) !important;
        stroke: var(--global--common-link--Color) !important;
      }
    }
    svg {
      display: inline-block;
      margin-right: size(10);
    }
    &.waiting_for_payment {
      svg {
        fill: var(--global--common-link--Color);
        stroke: var(--global--common-link--Color);
        width: size(11);
        height: size(11);
      }
    }
    &.payment_transaction_success {
      svg {
        fill: var(--global--success--BackgroundColor);
        stroke: var(--global--success--BackgroundColor);
        width: size(11);
        height: size(8);
      }
    }
    &.payment_transaction_failed,
    .payment_delayed {
      svg {
        fill: var(--global--error--BackgroundColor);
        stroke: var(--global--error--BackgroundColor);
        width: size(9);
        height: size(9);
      }
    }
  }
}
</style>
