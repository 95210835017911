<template>
  <div class="transaction">
    <h3>Transactions</h3>
    <div class="transaction__table" v-if="transactionInfo">
      <ul class="table-header">
        <li class="table-header__amount">AMOUNT</li>
        <li class="table-header__date">DATE</li>
        <li class="table-header__payment">NOTES</li>
        <li class="table-header__transaction-id">TRANSACTION ID</li>
        <li class="table-header__icons"></li>
      </ul>
      <template v-for="(transaction, index) in transactionInfo" :key="index">
        <ul class="table-body table-body__wrapper"
          v-if="transaction &&
          (transaction.payment_status.isPaymentSuccess || transaction.payment_status.isPaymentNotRequired)">
          <li
            class="table-body__amount"
            @click="openTransactionPage(transaction.key)"
          >
          {{ formatCurrency(transaction.amount, transaction.currency) }}
          <span v-if="transaction.kind !== 'money'">
            <svg>
              <use v-bind="{ 'xlink:href': '#virtual-money-icon' }" />
            </svg>
          </span>
          </li>
          <li
            class="table-body__date"
            @click="openTransactionPage(transaction.key)"
          >{{$filters.formatDate(transaction.created_at)}}</li>
          <li class="table-body__payment" @click="openTransactionPage(transaction.key)">
            {{transaction.notes ? transaction.notes : '-'}}
          </li>
          <li
            class="table-body__transaction-id"
            @click="openTransactionPage(transaction.key)"
          ><span v-if="transaction.status === 'cancelled'">{{transaction.status}}</span>
          {{ transaction.key }}
          </li>
            <li class="table-body__icons">
              <!-- <button>
                <svg class>
                  <use v-bind="{ 'xlink:href': '#notes-icon'}" />
                </svg>
              </button> -->
                <router-link
                    :to="{ name: 'transaction', params: { transactionkey: transaction.key,  } }">
                  <svg>
                    <use v-bind="{ 'xlink:href': '#eye-icon'}" />
                  </svg>
                </router-link>
                <button @click="sendEmail(transaction.key)"
                 :class="emailSentList.includes(transaction.key) ? 'mail-sent' : null">
                  <svg>
                    <use
                      v-if="emailSentList.includes(transaction.key)"
                      v-bind="{ 'xlink:href': '#mail-sent-icon'}"
                    />
                    <use v-else v-bind="{ 'xlink:href': '#mail-icon'}" />
                  </svg>
                  <p class="tool-tip"
                   :class="emailSentList.includes(transaction.key) && toolTipVisibility ? 'show' : null">
                   Email Successfully Sent</p>
                </button>
                <button @click="downloadBill(transaction.key)">
                  <svg>
                    <use v-bind="{ 'xlink:href': '#download-icon'}" />
                  </svg>
                </button>
            </li>
          </ul>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transaction {
  max-width: size(841);
  margin-top: size(55);

  h3 {
    @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
    margin-bottom: size(15);
  }

  &__table {
    padding: size(40) size(25) size(35);
    background: var(--global--white--Color);
    border-radius: size(10);
    .table {
      &-header,
      &-body {
        display: flex;
        &__amount {
          width: size(100);
          max-width: size(100);
        }
        &__date {
          width: size(130);
          max-width: size(130);
        }
        &__payment {
          width: size(200);
          max-width: size(200);
        }
        &__transaction-id {
          width: size(250);
          max-width: size(250);
          span {
            color: var(--global--error--BackgroundColor);
            font-size: size(12);
            line-height: size(14);
            text-transform: capitalize;
          }
        }
        &__icons {
          width: size(110);
          max-width: size(110);
        }
        svg {
          height: size(12);
          width: size(12);
        }
        li {
          word-wrap:break-word;
          white-space: normal;
          &:not(:first-of-type) {
            padding-left: size(30);
          }
          &:nth-last-child(1) {
            padding-left: size(0);
          }
          &:nth-last-child(2) {
            padding-right: size(40);
          }
        }
      }
      &-header {
        li {
          @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
          color: var(--global--link--dark--Color);
          text-align: left;
          padding-bottom: size(20);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
        }
      }
      &-body {
        &__amount {
          @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
            svg {
              width: size(25);
              height: size(25);
              display: inline-block;
              vertical-align: text-bottom;
              fill: var(--global--virtual-icon--BackgroundColor);
            }
        }
        &__date {
          @include font-styles(
            $type: 'label',
            $size: 'lg',
            $weight: 'semibold'
          );
        }
        &__payment {
          span {
            padding-left: size(10);
          }
          svg {
            fill: var(--global--success--BackgroundColor);
          }
        }
        &__icons {
          button {
            cursor: pointer;
            outline: none;
            position: relative;
            padding-left: size(25);
            .tool-tip {
              display: none;
              position: absolute;
              top: size(25);
              left: size(20);
              @include font-styles($type: 'label', $size: 'md', $weight: 'normal');
              padding: size(5);
              color: var(--global--success--BackgroundColor);
              min-width: max-content;
              border-radius: size(3);
              box-shadow: size(0) size(1) size(4) rgba(0, 0, 0, 0.15);
              z-index: 1;
              background: var(--global--white--Color);
              &.show {
                display: block;
              }
            }
            &:hover {
              svg {
                fill: var(--global--common-link--Color);
              }
              }
            &.mail-sent {
              svg {
                fill: var(--global--success--BackgroundColor);
              }
            }
          }
          a {
            padding-left: size(0);
          }
          }
          &__icons {
            svg {
              fill: var(--global--main--Color);
            }
          }
          button.mail-sent {
            &:hover {
              .tool-tip {
                display: block;
                &.show {
                  display: block;
                }
                // &.hide {
                //   display: none;
                // }
            }
          }
        }
        li {
          padding-bottom: size(16);
          padding-top: size(20);
          border-bottom: size(1) solid
            var(--global--line--table--BackgroundColor);
        }
        &:hover {
          cursor: pointer;
          .table-body {
            &__amount,
            &__date,
            &__payment,
            &__transaction-id,
            &__icons {
              color: var(--global--common-link--Color);
              button, a {
                svg {
                  fill: var(--global--common-link--Color);
                }
              }
            }
          }
        }
        &__wrapper {
          color: var(--global--main--Color);
        }
      }
    }
  }
}
</style>

<script>
import { currencyMethodsMixin } from '@/mixin/currency';
import { Transaction } from '@/store/modules/order';
import Config from '@/config';

export default {
  name: 'TransactionList',
  mixins: [currencyMethodsMixin],
  props: {
    transactionInfo: {
      type: Object,
    },
  },
  data() {
    return {
      emailSentStatus: false,
      emailSentList: [],
      toolTipVisibility: false,
      isLoading: true,
    };
  },
  methods: {
    downloadBill(transactionKey) {
      const appkey = Config.rzAppKey;
      const apikey = Config.rzApiKey;
      let url = null;
      // eslint-disable-next-line max-len
      url = `/backend/rest/business/transaction/${transactionKey}/?as_pdf=1&rz-api-key=${apikey}&rz-app-key=${appkey}`;
      window.open(url, '_blank');
    },
    sendEmail(transactionKey) {
      Transaction.sendTransactionEmail(transactionKey).then((resp) => {
        if (resp) {
          this.emailSentStatus = true;
          this.emailSentList.push(transactionKey);
          this.toolTipVisibility = true;
          setTimeout(() => {
            this.toolTipVisibility = false;
          }, 3000);
        }
      });
    },
    openTransactionPage(transactionkey) {
      this.$router.push({ name: 'transaction', params: { transactionkey } });
    },
  },
};
</script>
